.nav__menu {
	position: fixed;
	bottom: 0.3rem;
	background-color: var(--nav-menu-background);
	width: 90%;
	border-radius: 4rem;
	padding: 0.5rem;
	backdrop-filter: blur(10px);
	left: 0;
	right: 0;
	box-shadow: 0px 0px 20px -6px rgba(68, 73, 80, 0.8);
	margin: 0 auto;
	z-index: 100;
}

.nav__menu > ul {
	list-style: none;
}

.nav__list {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nav__link {
	color: var(--primary-text);
	font-size: 1.5rem;
	padding: 0.4rem;
	display: flex;
	border-radius: 5rem;
	border: none;
	background: transparent;
}

.hideNavMenu {
	opacity: 0;
}
/* Active link */
.active__link {
	background: linear-gradient(
		170deg,
		hsla(213, 79%, 50%, 1),
		rgb(230, 235, 240)
	);
	box-shadow: 0 0 20px hsla(213, 79%, 50%, 0.9);
	color: var(--primary-text);
}
/* For medium devices */
@media screen and (min-width: 576px) {
	.nav__menu {
		width: 400px;
	}
}

@media screen and (min-width: 757px) {
	.nav__menu {
		display: none;
	}
}
