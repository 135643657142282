.Profile {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	overflow-y: scroll;
	background-color: rgb(250, 250, 250);
	height: 100vh;
	padding-top: 1rem;
}

.profileLoader {
	display: flex;
	width: 100%;
	margin-top: 30vh;
	justify-content: center;
}

.profileUpper {
	width: 100%;
	display: flex;
	gap: 5rem;
	padding: 1.5rem;
	max-width: 62rem;
	margin-left: 3.5rem;
	justify-content: flex-start;
}

.profileUpper > img {
	width: 10rem;
	height: 10rem;
	border-radius: 50%;
}

.profileDetails {
	display: flex;
	flex-direction: column;
	position: relative;
}

.profileDetails > span {
	display: flex;
	align-items: center;
	min-width: 17rem;
}

.profileDetails > span > span {
	font-size: 1.75rem;
	font-weight: 300;
}

.profileDetails > span > button {
	margin-left: 1rem;
	padding: 0.5rem 1rem;
	font-size: 0.9rem;
	font-weight: 600;
}

.profileSettingsSvg {
	position: absolute;
	font-size: 1.7rem;
	right: 0.25rem;
	top: 0;
}

.profileSettingsSvg > div > button {
	outline: none;
	border: none;
	background: none;
	cursor: pointer;
}

.profileSettingsSvg > div > button > svg {
	font-size: 1.5rem;
}

.profileDetails > span > div {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1rem;
	font-size: 0.9rem;
	font-weight: 600;
	border: 1px solid black;
	border-radius: 10px;
	padding: 0.35rem 0.75rem;
	cursor: pointer;
	margin-right: 2.5rem;
}

.profileDetails > span > div > svg {
	font-size: 1rem;
	margin-left: 0.25rem;
}

.profileDetails > div:nth-child(2) {
	display: flex;
	margin-top: 1rem;
	gap: 2rem;
}

.profileDetails > div:nth-child(2) > span {
	cursor: pointer;
}

.profileDetails > div:nth-child(2) > span:nth-child(1) {
	font-size: 1rem;
	font-weight: 600;
}

.profileDetails > div:nth-child(2) > span:nth-child(1) > span {
	font-size: 1rem;
	font-weight: 300;
}

.profileDetails > div:nth-child(2) > span:nth-child(2) {
	font-size: 1rem;
	font-weight: 600;
}

.profileDetails > div:nth-child(2) > span:nth-child(2) > span {
	font-size: 1rem;
	font-weight: 300;
}

.profileDetails > div:nth-child(2) > span:nth-child(3) {
	font-size: 1rem;
	font-weight: 600;
}

.profileDetails > div:nth-child(2) > span:nth-child(3) > span {
	font-size: 1rem;
	font-weight: 300;
}

.profileDescription {
	margin-top: 1rem;
	display: flex;
	flex-direction: column;
}

.profileDescription > span:nth-child(1) {
	font-weight: 600;
	font-size: 1rem;
}

.profileDescription > span:nth-child(2) {
	margin-top: 0.25rem;
	font-size: 1rem;
	white-space: pre-wrap;
	font-weight: 300;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
}

.profileDescriptionMobile {
	display: none;
	width: 100%;
	align-items: flex-start;
	padding-left: 1.5rem;
	flex-direction: column;
	margin-bottom: 0.25rem;
}
.profileDescriptionMobile > span:nth-child(1) {
	font-weight: 600;
	font-size: 1rem;
}

.profileDescriptionMobile > span:nth-child(2) {
	margin-top: 0.25rem;
	font-size: 1rem;
	white-space: pre-wrap;
	font-weight: 300;
	width: 95%;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
}

.profileFollowersDataMobile {
	width: 100%;
	display: none;
}

.profileFollowersDataMobile > div {
	width: 100%;
	margin: 0.5rem 0;
	display: flex;
}

.profileFollowersDataMobile > div > div {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 33.3%;
}

.profileFollowersDataMobile > div > div > span:nth-child(1) {
	font-size: 1rem;
	font-weight: 600;
}

.profileFollowersDataMobile > div > div > span:nth-child(2) {
	font-size: 1rem;
	color: var(--secondary-text);
}

.profileUserStatus {
	display: flex;
	width: 100%;
	margin-top: 0.75rem;
	font-weight: 400;
	gap: 2rem;
}

.profileGallery {
	width: 100%;
	max-width: 67rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin-top: 1rem;
	row-gap: 1.5rem;
	column-gap: 1.5rem;
	padding: 0 1rem;
	grid-auto-rows: calc((67rem - 5rem) / 3);
	margin-bottom: 5rem;
}

.profileGallery > div {
	background-repeat: no-repeat;
	background-size: cover;
}

.profileUpperDivider {
	width: 95%;
	max-width: 67rem;
}

@media only screen and (max-width: 1072px) {
	.profileUpper {
		padding: 1.5rem;
		gap: 4rem;
		margin-left: 0;
	}
}

@media only screen and (max-width: 688px) {
	.profileUpper > img {
		width: 5rem;
		height: 5rem;
	}
	.profileUpper {
		gap: 2rem;
	}
	.profileDescription {
		display: none;
	}
	.profileDescriptionMobile {
		display: flex;
	}
	.profileDetails > span {
		flex-direction: column;
		align-items: flex-start;
	}
	.profileDetails > span > div {
		margin-top: 0.25rem;
		margin-left: 0;
		width: 100%;
	}
	.profileSettingsSvg {
		right: 0;
		top: 0.25rem;
		left: 90%;
	}
	.profileDetails > span {
		min-width: 2rem;
	}
	.profileDetails > span > span {
		margin-right: 1.8rem;
	}
	.profileDescriptionMobile {
		padding-left: 1rem;
	}
	.profileUpperDivider {
		display: none;
	}
	.profileFollowersDataMobile {
		display: block;
	}
	.profileDetails > div:nth-child(2) {
		display: none;
	}
	.profileDetails > span > button {
		margin-left: 0;
		width: 100%;
	}
}

@media only screen and (max-width: 756px) {
	.profileGallery {
		row-gap: 0.17rem;
		column-gap: 0.17rem;
		margin-top: 0;
		padding: 0;
		grid-auto-rows: calc((100vw - 0.5rem) / 3);
	}
	.profileUpper {
		padding: 1rem;
	}
}

@media only screen and (max-width: 465px) {
}

@media only screen and (max-width: 1072px) and (min-width: 757px) {
	.profileGallery {
		grid-auto-rows: calc((100vw - 5rem) / 3);
	}
}
