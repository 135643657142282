.ProfileSide {
	box-sizing: content-box;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 2% 1rem 0 1rem;
	width: 100%;
	max-width: 320px;
}

.ProfileSide > hr {
	width: 100%;
}

.profileSideFooter {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
}
.profileSideFooter > span {
	font-size: 0.7rem;
	color: var(--secondary-text);
}

@media screen and (min-width: 0px) and (max-width: 1023px) {
	.ProfileSide {
		display: none;
	}
}
