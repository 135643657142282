.FollowersCard {
	width: 100%;
	border-radius: 0.75rem;
	gap: 1rem;
	display: flex;
	flex-direction: column;
	font-size: 13px;
	margin: 1.5rem 0 2.5rem 0;
}

.FollowersCard > span {
	font-size: 1rem;
	font-weight: 600;
	color: var(--secondary-text);
}

.follower {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.follower > div {
	display: flex;
	gap: 10px;
}

.follower > div > div:nth-child(1) {
	width: 3.5rem;
	height: 3.5rem;
	min-width: 3.5rem;
	min-height: 3.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
.haveStory > .followerImage {
	border: 2px solid white;
}
.followerImage {
	width: 3.2rem;
	height: 3.2rem;
	border-radius: 50%;
	cursor: pointer;
}

.name {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;
	justify-content: center;
	white-space: nowrap;
	max-width: 7.5rem;
	overflow: hidden;
	cursor: pointer;
}

.name > span:nth-of-type(1) {
	font-weight: bold;
}

.fc-button {
	height: 2rem;
	padding: 0 20px;
	font-weight: 600;
}
