.Story {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 4.5rem;
	overflow: hidden;
	white-space: nowrap;
}

.Story > .haveStory {
	width: 4rem;
	height: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.StoryImg {
	width: 3.6rem;
	height: 3.6rem;
	border-radius: 50%;
	border: 3px solid white;
}

.Story > span {
	font-size: 0.8rem;
}
