.loader {
	box-sizing: border-box;
	position: relative;
	width: 24px;
	height: 24px;
	animation: spin 1s linear infinite;
}
.loader:after,
.loader:before {
	content: "";
	width: 12px;
	height: 12px;
	position: absolute;
	border-radius: 50%;
	background: var(--primary1);
	animation: spin 1s linear infinite;
	transform-origin: 0px 100%;
}
.loader:before {
	transform-origin: 0 50%;
	background: var(--primary2);
}
@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}
