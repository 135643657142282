.postModel {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem 0;
}
.postModel > span:nth-child(1) {
	font-size: 1.2rem;
	font-weight: 700;
}

.postModel > hr {
	width: 100%;
	color: rgb(212, 212, 212);
	margin-top: 0.25rem;
}

.postModelContent {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.postModelProfilData {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 0.75rem;
}

.postModelProfilData > img {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
}

.postModelProfilData > div {
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
}

.postModelProfilData > div > span {
	font-size: 0.9rem;
	font-weight: 700;
}

.postModelProfilData > div > div {
	background: var(--comment-background);
	display: flex;
	align-items: center;
	padding: 0.2rem 0.3rem;
	gap: 0.3rem;
	width: max-content;
	border-radius: 5px;
}

.postModelProfilData > div > div > span {
	font-size: 0.8rem;
	font-weight: 600;
}
.postModelContent > textarea {
	resize: none;
	height: 4rem;
	width: 100%;
	margin-top: 1rem;
	padding: 0.5rem;
	border-radius: 10px;
	outline: none;
	border: none;
	background: var(--comment-background);
}

.postmodelImageInput {
	position: relative;
	width: 100%;
	height: 16.7rem;
	border-radius: 10px;
	margin-top: 1rem;
	padding: 0.5rem;
}

.postmodelImageInputNoPhoto {
	border: 1px solid rgb(218, 218, 218);
	border-radius: 3px;
	height: 19rem;
}

.postmodelImageInputSelectPhoto {
	background: var(--comment-background);
	width: 100%;
	height: 100%;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: 600;
}

.aspectRatioSetter {
	width: 100%;
	height: 2.5rem;
	margin-top: 1px;
	background: rgba(0, 0, 0, 0.25);
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.aspectRatioSetter > span {
	border: 2px solid rgb(92, 92, 92);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.7rem;
	cursor: pointer;
	border-radius: 5px;
}
.aspectRatioSetter > span:nth-child(1) {
	width: 1.2rem;
	height: 1.2rem;
}

.aspectRatioSetter > span:nth-child(2) {
	width: 1.4rem;
	height: 2rem;
}

.aspectRatioSetter > span:nth-child(3) {
	width: 2.292rem;
	height: 1.2rem;
}

.aspectRatioSetter span:hover {
	border: 2px solid rgb(70, 70, 70);
	background: rgb(153, 153, 153);
}

.postCancelOption {
	width: 100%;
	height: 2.2rem;
	margin-top: 1.2px;
	background: rgba(0, 0, 0, 0.25);
	border-end-end-radius: 8px;
	border-end-start-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	gap: 0.5rem;
	font-size: 1.1rem;
	font-weight: 600;
	color: rgb(92, 92, 92);
	transition: all 0.3s;
	margin-bottom: 0.75rem;
}

.postCancelOption > svg {
	font-size: 1.3rem;
	transition: all 0.3s;
}

.postCancelOption:hover {
	background: rgba(255, 65, 65, 0.25);
	color: red;
	font-size: 0;
}

.postCancelOption:hover > svg {
	font-size: 1.8rem;
}

.aspectRatioSetter > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: 5px;
	margin: 0.25rem;
	padding: 0.15rem;
	background-color: rgba(255, 133, 133, 0.63);
}

.aspectRatioSetter > div > svg {
	font-size: 1.3 rem;
	color: rgb(218, 0, 0);
}
.aspectRatioSetter > div {
	font-size: 0.8rem;
	color: rgb(218, 0, 0);
}

.aspectRatioSetter div:hover {
	background: rgb(153, 153, 153);
}

.postmodelImageInputSelectPhoto > svg {
	font-size: 2rem;
}

.postmodelImageInputSelectPhoto:hover {
	background: rgb(233, 233, 233);
}
.profilemodelLocationInput {
	width: 100%;
	border-radius: 10px;
	border: 2px solid rgb(207, 207, 207);
	display: flex;
	align-items: center;
	margin-top: 1rem;
}
.profilemodelLocationInput > svg {
	color: var(--secondary-text);
	font-size: 1.5rem;
	margin-left: 0.35rem;
}
.profilemodelLocationInput > input {
	width: 100%;
	padding: 0.5rem;
	border: none;
	outline: none;
	background: none;
	height: 2.75rem;
	color: var(--secondary-text);
	font-weight: 500;
}
.postModelButtonWrapper {
	width: 100%;
	padding: 0 1rem;
}
.postModelButton {
	font-family: inherit;
	position: relative;
	z-index: 1;
	font-size: 0.9rem;
	background: var(--primary-button-background2);
	color: white;
	padding: 0.5em 0.8em;
	padding-left: 0.9em;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	border-radius: 16px;
	overflow: hidden;
	transition: all 250ms;
	width: 100%;
}

.postModelButtonTextLoading {
	opacity: 0;
	width: 0px;
	height: 0px;
	transition: all 0.3s ease-in-out;
	font-size: 1.2rem;
	font-weight: 500;
}

.postModelButton > .postModelButtonText {
	display: block;
	margin-left: 0.3em;
	transition: all 0.3s ease-in-out;
	font-size: 1.2rem;
	font-weight: 600;
}

.postModelButton svg {
	display: block;
	transform-origin: center center;
	transition: transform 0.3s ease-in-out;
}

.postModelButtonActive .svg-wrapper {
	animation: fly-1 0.6s ease-in-out infinite alternate;
}

.postModelButtonActive svg {
	transform: translateX(8em) scale(1.1);
}

.postModelButtonActive > .postModelButtonText {
	display: none;
}
.postModelButtonActive > .postModelButtonTextLoading {
	opacity: 1;
	width: auto;
	height: auto;
	transform: translateX(-2em) scale(1.1);
}

.postModelButtonActive {
	transform: scale(0.95);
}

.postModelButton::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	border-radius: 10px;
	background: linear-gradient(
		90deg,
		rgba(0, 255, 144, 1) 0%,
		rgba(0, 161, 247, 1) 100%
	);
	z-index: -1;
	-webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
	box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
	transition: all 250ms;
}

.postModelButtonActive::before {
	width: 100%;
}

.postmodelImageInput > .reactEasyCrop_Container {
	border-start-end-radius: 8px;
	border-start-start-radius: 8px;
}
.reactEasyCrop_CropArea {
	color: rgba(0, 0, 0, 0.25) !important;
}

@keyframes fly-1 {
	from {
		transform: translateY(0.1em);
	}

	to {
		transform: translateY(-0.1em);
	}
}
