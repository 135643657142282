.Home {
	position: relative;
	display: flex;
	justify-content: center;
}

@media screen and (min-width: 0px) and (max-width: 1023px) {
	.Home {
		display: block;
	}
}
