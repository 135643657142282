.PostShare {
	display: flex;
	width: 100%;
	max-width: 40rem;
	gap: 1rem;
	background-color: var(--white-background);
	padding: 1rem;
	border-radius: 0.75rem;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: 10;
}

.postShareUpper > hr {
	width: 95%;
	border-color: rgb(255, 255, 255);
	color: red;
	align-self: center;
}

.postShareUpper {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1rem;
}

.postShareSearchArea {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 1rem;
}

.postShareSearchArea > div {
	width: 3.5rem;
	height: 3.5rem;
	min-width: 3.5rem;
	min-height: 3.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.haveStory > .postShareSearchAreaImage {
	border: 2px solid white;
}

.postShareSearchAreaImage {
	border-radius: 50%;
	width: 3.1rem;
	height: 3.1rem;
}

.postShareInput {
	background-color: var(--comment-background);
	border-radius: 20px;
	width: 100%;
	padding: 10px;
	font-size: 17px;
	font-weight: 200;
	border: none;
	outline: none;
}

.postShareInput::placeholder {
	font-weight: 500;
}

.postOptions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 3rem;
}

.option {
	padding: 5px 10px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 15px;
	font-weight: 600;
	color: var(--secondary-text);
	cursor: pointer;
}
.option > svg {
	font-size: 1.5rem;
	margin-right: 5px;
}

/* From uiverse.io by @adamgiebl */
.postShareButton {
	font-family: inherit;
	position: relative;
	z-index: 1;
	font-size: 0.9rem;
	background: var(--primary-button-background2);
	color: white;
	padding: 0.5em 0.8em;
	padding-left: 0.9em;
	display: flex;
	align-items: center;
	border: none;
	border-radius: 16px;
	overflow: hidden;
	/* transition: all 0.2s; */
	transition: all 250ms;
}

.postShareButton span {
	display: block;
	margin-left: 0.3em;
	transition: all 0.3s ease-in-out;
}

.postShareButton svg {
	display: block;
	transform-origin: center center;
	transition: transform 0.3s ease-in-out;
}

.postShareButtonActive .svg-wrapper {
	animation: fly-1 0.6s ease-in-out infinite alternate;
}

.postShareButtonActive svg {
	transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.postShareButtonActive span {
	transform: translateX(5em);
}

.postShareButtonActive {
	transform: scale(0.95);
}

.postShareButton::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	border-radius: 10px;
	background: linear-gradient(
		90deg,
		rgba(0, 255, 144, 1) 0%,
		rgba(0, 161, 247, 1) 100%
	);
	z-index: -1;
	-webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
	box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
	transition: all 250ms;
}

.postShareButtonActive::before {
	width: 100%;
}

@keyframes fly-1 {
	from {
		transform: translateY(0.1em);
	}

	to {
		transform: translateY(-0.1em);
	}
}

.previewImage {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.disSelectIcon {
	position: absolute;
	width: 100%;
	max-width: 40rem;
	top: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.disSelectIcon > svg {
	cursor: pointer;
	margin-right: 0.5rem;
	background: white;
	border-radius: 50%;
	font-size: 1.5rem;
}

.previewImage > img {
	width: 100%;
	max-width: 40rem;
	max-height: 40rem;
	object-fit: cover;
	border-radius: 0.5rem;
}

@media screen and (min-width: 0px) and (max-width: 489px) {
	.PostShare {
		padding: 0.5rem;
	}
	.option {
		padding: 2.5px 5px;
	}
}

@media screen and (min-width: 0px) and (max-width: 642px) {
	.PostShare {
		border-radius: 0;
		/* position: sticky;
		top: -5.25rem; */
	}
	.postOptions {
		margin-left: 0;
	}
	/* .showShadow:before {
		content: "";
		z-index: -1;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(
			to right,
			var(--primary1) 38%,
			var(--primary2) 51%
		);
		transform: translate3d(0px, 1px, 0) scale(1);
		filter: blur(4px);
		opacity: 0.6;
		transition: opacity 0.3s;
		border-radius: inherit;
	} */

	/* 
	* Prevents issues when the parent creates a 
	* stacking context. (For example, using the transform
	* property )
	*/
	/* .showShadow::after {
		content: "";
		z-index: -1;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: inherit;
		border-radius: inherit;
	} */
}
