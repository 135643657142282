.infoForm {
	display: flex;
	flex-direction: column;
	position: relative;
}

.infoFormProfilePic {
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-bottom: 1rem;
	margin-left: 1.2rem;
}

.infoFormProfilePic > img {
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
}

.infoFormProfilePic > div {
	display: flex;
	flex-direction: column;
}

.infoFormProfilePic > div > span:nth-child(1) {
	font-size: 1.3rem;
	font-weight: 500;
}

.infoFormProfilePic > div > span:nth-child(2) {
	font-size: 0.8rem;
	font-weight: 800;
	color: #0095f6;
	cursor: pointer;
	width: max-content;
	border-bottom: 1px solid #ffffff;
}

.infoFormProfilePic > div > span:nth-child(2):hover {
	border-bottom: 1px solid #0095f6;
}

.inputFormInputs {
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	margin-bottom: 1rem;
}

.inputFormInputs > div {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.inputFormInputs > div > span {
	font-size: 0.9rem;
	font-weight: 600;
	width: 6rem;
	text-align: right;
}

.inputFormInputs > div > input {
	height: 2rem;
	font-size: 0.9rem;
	width: 245px;
	font-weight: 400;
	padding: 0.5rem;
	border: 1px solid rgb(202, 202, 202);
	border-radius: 5px;
}

.inputFormInputs > div > textarea {
	font-size: 0.9rem;
	font-weight: 400;
	width: 245px;
	height: 6rem;
	resize: none;
	padding: 0.5rem;
	border: 1px solid rgb(202, 202, 202);
	border-radius: 5px;
}

.relationshipRadio input {
	cursor: pointer;
}

.relationshipRadio > div {
	width: 245px;
}
.relationshipRadio > div > div > div {
	width: 6rem;
}
.relationshipRadio > div > div > div > label {
	font-size: 0.9rem;
	margin-left: 5px;
}

.editProfileButtons {
	display: flex;
	width: 100%;
	gap: 1.5rem;
	justify-content: flex-end;
}

.editProfileCancelButton {
	background: none;
	color: var(--primary2);
	font-weight: 600;
	border-radius: 10px;
	font-size: 17px;
	border: 2px solid var(--primary2);
	transition: all 0.3s;
}

.editProfileCancelButton:hover {
	background: var(--primary2);
	color: white;
}

.editProfileButton {
	padding: 0.5rem 1rem;
	cursor: pointer;
}
.ProfileCrop {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.493);
	backdrop-filter: saturate(180%) blur(8px);
}

.profileCropper {
	position: relative;
	width: 100%;
	height: 13.5rem;
}

.profileCropperOption {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 100%;
	padding: 0.4rem;
	background: rgba(255, 255, 255, 0.726);
	backdrop-filter: saturate(180%) blur(1px);
}

.profileCropperOption span {
	font-size: 1rem;
	padding: 0.1rem 0.5rem;
	width: 5rem;
	text-align: center;
	font-weight: 600;
	border-radius: 10px;
	border: 2px solid var(--primary2);
	cursor: pointer;
}

.profileCropperOption span:nth-child(1) {
	color: var(--primary2);
}

.profileCropperOption span:nth-child(2) {
	color: white;
	background: var(--primary2);
}
