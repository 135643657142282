.Stories {
	margin-top: 2vw;
	background-color: var(--white-background);
	padding: 1rem;
	border-radius: 10px;
	width: 100%;
	max-width: 40rem;
}

.Stories > div {
	display: flex;
	align-items: center;
	gap: 0.75rem;
	overflow-x: scroll;
}
.noStoryUser {
	background: linear-gradient(
		135deg,
		rgb(209, 209, 209) 0%,
		rgb(161, 161, 161) 100%
	);
	border-radius: 50%;
	width: 4rem;
	height: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.addStorySignUser {
	position: absolute;
	right: 2px;
	background: linear-gradient(
		135deg,
		rgba(0, 255, 144, 1) 0%,
		rgba(0, 161, 247, 1) 100%
	);
	border-radius: 50%;
	width: 1.2rem;
	height: 1.2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 2px;
}

.addStorySignUser > svg {
	font-size: 0.7rem;
	color: white;
}
