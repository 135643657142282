.Posts {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 30vh;
	width: 100%;
}

.postLoader {
	margin-top: 5rem;
	display: flex;
	width: 100%;
	justify-content: center;
}

.followMessage {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 40rem;
}

.followMessage > span:nth-child(1) {
	color: var(--secondary-text);
	font-size: 1rem;
	margin: 1rem 5px;
}

.followMessage > span:nth-child(2) {
	font-size: 1rem;
	font-weight: 600;
	margin: 1rem 0.5rem 5px;
}

@media only screen and (min-width: 1024px) {
	.Posts {
		width: 42rem;
	}
}
