.StatusBar {
	width: 100%;
	height: 3.3rem;
	position: sticky;
	display: flex;
	align-items: center;
	top: 0;
	z-index: 100;
	background-color: var(--white-background);
	gap: 1rem;
	-webkit-box-shadow: 0px 1px 10px 0px rgba(68, 73, 80, 1);
	-moz-box-shadow: 0px 1px 10px 0px rgba(68, 73, 80, 1);
	box-shadow: 0px 1px 10px 0px rgba(68, 73, 80, 1);
}

.statusBarLogo {
	margin-left: 1rem;
}

.statusBarLogo > img {
	width: 8rem;
}
.statusBarSearch {
	position: relative;
	display: flex;
	align-items: center;
	background-color: var(--comment-background);
	border-radius: 50px;
	height: 75%;
	margin-left: 1rem;
	width: 20rem;
	cursor: text;
}

.statusBarSearch > svg {
	margin: 0 0.75rem 0 auto;
	cursor: pointer;
	font-size: 1.4rem;
	color: var(--secondary-text);
}

.statusBarSearch > input {
	background-color: transparent;
	border: none;
	outline: none;
	padding: 1rem;
	font-size: 1rem;
	color: var(--primary-text);
}

.statusBarSearch > input::placeholder {
	color: #606770;
}

/* .statusBarSearch:focus-within {
	-webkit-box-shadow: 0px 0px 10px -4px rgba(68, 73, 80, 1);
	-moz-box-shadow: 0px 0px 10px -4px rgba(68, 73, 80, 1);
	box-shadow: 0px 0px 10px -4px rgba(68, 73, 80, 1);
} */

.menu {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin: 0 1rem 0 auto;
}

.menu > div {
	width: 40px;
	height: 40px;
	background-color: var(--secondary-button-background);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.menu > div:hover {
	background-color: var(--secondary-button-background-on-hover);
}

.menu > div > svg {
	font-size: 1.5rem;
}

.statusBarProfilePicture {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

@media only screen and (max-width: 756px) and (min-width: 0px) {
	.menu {
		display: none;
	}
}

@media only screen and (max-width: 503px) and (min-width: 0px) {
	.statusBarSearch {
		width: 15rem;
		margin-left: 0;
	}
	.statusBarSearch > input {
		width: 80%;
	}
}
@media only screen and (max-width: 407px) and (min-width: 0px) {
	.StatusBar {
		gap: 0.75rem;
	}
	.statusBarLogo {
		margin-left: 0.7rem;
	}
	.statusBarSearch {
		margin-right: 0.25rem;
	}
}
