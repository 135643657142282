#PostSide {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: scroll;
	width: 100%;
	width: auto;
	align-items: center;
	margin: 0 5% 0 5%;
}

@media screen and (min-width: 0px) and (max-width: 1170px) {
	#PostSide {
		margin: 0;
	}
}

#PostSide::-webkit-scrollbar {
	display: none;
}
