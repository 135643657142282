.ExploreSlide {
	display: flex;
	overflow-x: scroll;
	overflow-y: hidden;
	width: 100%;
	max-width: 40rem;
	padding: 4px;
	gap: 0.75rem;
}

.exploreSlideUser {
	height: 13rem;
	background: var(--white-background);
	width: 10rem;
	min-width: 10rem;
	border-radius: 0.75rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0.75rem;
	box-shadow: 0px 0px 9px -4px rgba(68, 73, 80, 1);
}

.exploreSlideImage {
	width: 5.5rem;
	height: 5.5rem;
	border-radius: 50%;
	cursor: pointer;
}

.exploreSlideUserUpper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.exploreSlideUserLower {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.exploreSlideUserLower > span:nth-child(1) {
	font-size: 1rem;
	font-weight: 500;
	margin-top: 0.5rem;
	cursor: pointer;
}

.exploreSlideUserLower > span:nth-child(2) {
	font-size: 0.8rem;
	font-weight: 400;
	margin-bottom: 0.5rem;
	cursor: pointer;
}

@media only screen and (max-width: 670px) {
	.ExploreSlide {
		margin-left: 0.75rem;
	}
}
