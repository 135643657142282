@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");

:root {
	--white-background: #ffffff;
	--primary-button-background2: #00a1f7;
	--primary-button-background1: #00ff90;
	--secondary-button-background: #e4e6eb;
	--secondary-button-background-on-hover: #ced0d5;
	--primary-text: #050505;
	--secondary-text: #84888f;
	--comment-background: #f0f2f5;
	--nav-menu-background: #f3f8ff9d;
	--body-background: #f0f2f5;

	--story-icon-color: #4cb256;
	--add-photo-icon-color: #4a4eb7;
	--location-icon-color: #ef5757;
	--primary1: #00ff90;
	--primary2: #00a1f7;
}

* {
	font-family: "Inter", sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	background-color: #f0f2f5;
	width: 100%;
	height: var(--vh);
	position: fixed;
}

.button {
	border: unset;
	border-radius: 10px;
	color: #212121;
	z-index: 1;
	background: rgba(0, 161, 247, 1);
	position: relative;
	font-weight: 1000;
	font-size: 17px;
	transition: all 250ms;
	overflow: hidden;
	color: white;
}

.button::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	border-radius: 10px;
	background: linear-gradient(
		90deg,
		rgba(0, 255, 144, 1) 0%,
		rgba(0, 161, 247, 1) 100%
	);
	z-index: -1;
	-webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
	box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
	transition: all 250ms;
}

.button:hover::before {
	width: 100%;
}

/* ::-webkit-scrollbar {
	display: none;
} */
.haveStory {
	background: linear-gradient(
		135deg,
		rgba(0, 255, 144, 1) 0%,
		rgba(0, 161, 247, 1) 100%
	);
	border-radius: 50%;
}
::-webkit-scrollbar {
	display: none;
}
