.Post {
	display: flex;
	flex-direction: column;
	background-color: var(--white-background);
	border-radius: 0.75rem;
	max-width: 40rem;
	box-shadow: 0px 0px 9px -4px rgba(68, 73, 80, 1);
	width: 100%;
}

.postHeader {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 3.75rem;
}

.postHeader > img {
	width: 2.2rem;
	height: 2.2rem;
	border-radius: 50%;
	margin: 0.5rem;
	cursor: pointer;
}

.postHeaderDetails {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
}

.postHeaderDetails > span:nth-child(1) {
	font-size: 0.9rem;
	font-weight: 600;
	cursor: pointer;
}
.postHeaderDetails > span:nth-child(2) {
	font-size: 0.7rem;
	font-weight: 500;
	color: var(--secondary-text);
}

.postHeader > div:nth-child(3) {
	margin-left: auto;
	margin-right: 1rem;
	font-size: 1.3rem;
}

.postHeader > div:nth-child(3) > div > button {
	outline: none;
	border: none;
	border-radius: 50%;
	width: 1.3rem;
	height: 1.3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background: none;
}

.postHeader > div:nth-child(3) > div > button:hover {
	background: var(--body-background);
}

.postHeader > div:nth-child(3) > div > button > svg {
	font-size: 1.2rem;
}

.postImage > img {
	width: 100%;
	max-width: 40rem;
	max-height: 40rem;
	object-fit: cover;
	border-top: 1px solid rgba(198, 198, 198, 0.617);
	border-bottom: 1px solid rgb(198, 198, 198, 0.617);
}

.postDetails {
	display: flex;
	align-items: flex-start;
	padding: 0.75rem 0 1rem 0.75rem;
	flex-direction: column;
}
.postReact {
	display: flex;
	align-items: flex-start;
	gap: 0.75rem;
	margin-bottom: 0.25rem;
}

.postReact > svg {
	font-size: 1.7rem;
	cursor: pointer;
}

.postDetails > span {
	font-size: 0.9rem;
	font-weight: 600;
	margin-bottom: 0.45rem;
}
.postDesc {
	margin-bottom: 0.25rem;
}

.postDesc > span:nth-child(1) {
	font-size: 0.9rem;
	font-weight: 600;
	cursor: pointer;
}
.postDesc > span:nth-child(2) {
	font-size: 0.9rem;
	white-space: pre-wrap;
}
.comment {
	font-size: 0.9rem;
	color: var(--secondary-text);
}

.comment:hover {
	text-decoration: underline;
	cursor: pointer;
}

.postTime {
	font-size: 0.75rem;
	color: var(--secondary-text);
	margin-top: 0.25rem;
}

@media screen and (min-width: 0px) and (max-width: 642px) {
	.Post {
		border-radius: 0;
		margin: 0;
	}
}
