.storyModal {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem 0;
}

.storyModal > span:nth-child(1) {
	font-size: 1.2rem;
	font-weight: 700;
}
.storyModal > hr {
	width: 100%;
	color: rgb(212, 212, 212);
	margin-top: 0.25rem;
}

.storyModalContent {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.storyModalImageInput {
	position: relative;
	width: 100%;
	height: 28rem;
	border-radius: 10px;
	padding: 0.5rem;
}

.storyModalImageInputNoPhoto {
	border: 1px solid rgb(218, 218, 218);
	border-radius: 3px;
	height: 19rem;
}

.storyModalImageInputSelectPhoto {
	background: var(--comment-background);
	width: 100%;
	height: 100%;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: 600;
}

.storyModalImageInputSelectPhoto > svg {
	font-size: 2rem;
}

.storyModalImageInputSelectPhoto:hover {
	background: rgb(233, 233, 233);
}

.storyDeleteOption {
	width: 100%;
	height: 2.7rem;
	margin-top: 1px;
	background: rgba(255, 115, 115, 0.25);
	border-end-end-radius: 8px;
	border-end-start-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	gap: 0.5rem;
	font-size: 1.1rem;
	font-weight: 600;
	color: red;
	transition: all 0.3s;
	margin-top: 0.15rem;
}

.storyDeleteOption > svg {
	font-size: 1.3rem;
	transition: all 0.5s;
}

.storyDeleteOption:hover {
	background: rgba(255, 65, 65, 0.25);
	font-size: 0;
}

.storyDeleteOption:hover > svg {
	font-size: 1.8rem;
}

.storyModalImageInput > .reactEasyCrop_Container {
	border-start-end-radius: 8px;
	border-start-start-radius: 8px;
}

.storyModalButtonWrapper {
	width: 100%;
	padding: 0 1rem;
}
.storyModalButton {
	font-family: inherit;
	position: relative;
	z-index: 1;
	font-size: 0.9rem;
	background: var(--primary-button-background2);
	color: white;
	padding: 0.5em 0.8em;
	padding-left: 0.9em;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	border-radius: 16px;
	overflow: hidden;
	transition: all 250ms;
	width: 100%;
}

.storyModalButtonTextLoading {
	opacity: 0;
	width: 0px;
	height: 0px;
	transition: all 0.3s ease-in-out;
	font-size: 1.2rem;
	font-weight: 500;
}

.storyModalButton > .storyModalButtonText {
	display: block;
	margin-left: 0.3em;
	transition: all 0.3s ease-in-out;
	font-size: 1.2rem;
	font-weight: 600;
}

.storyModalButton svg {
	display: block;
	transform-origin: center center;
	transition: transform 0.3s ease-in-out;
}

.storyModalButtonActive .svg-wrapper {
	animation: fly-1 0.6s ease-in-out infinite alternate;
}

.storyModalButtonActive svg {
	transform: translateX(8em) scale(1.1);
}

.storyModalButtonActive > .storyModalButtonText {
	display: none;
}
.storyModalButtonActive > .storyModalButtonTextLoading {
	opacity: 1;
	width: auto;
	height: auto;
	transform: translateX(-2em) scale(1.1);
}

.storyModalButtonActive {
	transform: scale(0.95);
}

.storyModalButton::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	border-radius: 10px;
	background: linear-gradient(
		90deg,
		rgba(0, 255, 144, 1) 0%,
		rgba(0, 161, 247, 1) 100%
	);
	z-index: -1;
	-webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
	box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
	transition: all 250ms;
}

.storyModalButtonActive::before {
	width: 100%;
}

.storymodalImageInput > .reactEasyCrop_Container {
	border-start-end-radius: 5px;
	border-start-start-radius: 5px;
}
.reactEasyCrop_CropArea {
	color: rgba(0, 0, 0, 0.4) !important;
}

@keyframes fly-1 {
	from {
		transform: translateY(0.1em);
	}

	to {
		transform: translateY(-0.1em);
	}
}
