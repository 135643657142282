.Auth {
	overflow: scroll;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: var(--vh);
	position: relative;
	gap: 5rem;
}

.authLogo {
	height: 6rem;
	align-self: center;
}
.authForm {
	border-radius: 10px;
	background: linear-gradient(145deg, #e8e8e8, #f0f0f0);
	padding: 1rem;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	box-shadow: 8px 8px 12px #848484, -8px -8px 12px #ffffff;
	width: 23rem;
}

.authTitle {
	margin: 0 0 0.5rem 0;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--secondary-text);
}

.authInputs {
	display: flex;
	flex-direction: column;
	gap: 1.8rem;
	margin-bottom: 0.5rem;
}

.inputField {
	display: flex;
	align-items: center;
	border-radius: 10px;
	padding: 10px;
	box-shadow: inset 5px 5px 7px #afafaf, inset -5px -5px 7px #ffffff;
}

.inputField > svg {
	font-size: 1.2rem;
	color: var(--primary-color);
}

.inputField > input {
	outline: none;
	border: none;
	font-size: 1rem;
	padding: 1px 1px 1px 5px;
	color: #4d5157;
	background: none;
	font-weight: 500;
	flex-grow: 1;
}

.authWarning {
	margin-bottom: 1rem;
	font-size: 0.7rem;
	color: red;
}
.authLink {
	font-size: 0.8rem;
	font-weight: 400;
}

.authLink > span {
	cursor: pointer;
	color: var(--primary2);
	font-weight: 600;
	border-bottom: 2.5px solid var(--primary1);
}

.authButton {
	margin-top: 1.5rem;
	padding: 10px;
}

.authForm > p {
	font-size: 0.6rem;
	margin-top: 1rem;
}

.authFooter {
	position: absolute;
	bottom: 1rem;
	color: var(--secondary-text);
	font-size: 0.8rem;
}
.authFooter > span:nth-child(1) {
	margin-right: 1rem;
}

@media only screen and (max-width: 425px) and (min-width: 0px) {
	.authForm {
		/* border-radius: 0; */
		width: 90%;
		/* min-height: 100vh;
		box-shadow: none; */
	}

	/* .authFooter {
		display: none;
	} */
}
