.ProfileCard {
	border-radius: 1rem;
	padding: 0.2rem 2rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	background: linear-gradient(145deg, #cacaca, #f0f0f0);
	box-shadow: 9px 9px 12px #929292, -9px -9px 12px #ffffff;
}

.ProfileName {
	display: flex;
	align-items: center;
}

.ProfileName > div:nth-child(1) {
	width: 3.5rem;
	height: 3.5rem;
	min-width: 3.5rem;
	min-height: 3.5rem;
	display: flex;
	justify-content: center;
	margin: 0.7rem 0.7rem 0.7rem 0;
	align-items: center;
	border-radius: 50%;
}

.profileCardImage {
	width: 3.1rem;
	height: 3.1rem;
	border-radius: 50%;
	cursor: pointer;
}
.haveStory > .profileCardImage {
	border: 2px solid white;
}
.profileNames {
	display: flex;
	flex-direction: column;
	cursor: pointer;
}

.profileNames > span:nth-child(1) {
	font-size: 1rem;
	font-weight: 600;
}

.profileNames > span:nth-child(2) {
	font-size: 0.75rem;
	color: var(--secondary-text);
}

/* follow status */
.followStatus {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.2rem;
	margin-bottom: 0.3rem;
}

.followStatus > hr {
	width: 85%;
	border: 1px solid var(--secondary-button-background-on-hover);
}

.followStatus > div {
	display: flex;
	width: 80%;
	justify-content: space-between;
	align-items: center;
}

.follow {
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	align-items: center;
	justify-content: center;
}

.follow > span:nth-of-type(1) {
	font-weight: bold;
}

.follow > span:nth-of-type(2) {
	color: var(--secondary-text);
	font-size: 13px;
}

.vl {
	height: 2rem;
	border-left: 2px solid var(--secondary-button-background-on-hover);
}
