.PostPage {
	background: var(--white-background);
	height: 100vh;
}

.commentLoader {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 20vh;
}
.addCommentSection {
	background: var(--comment-background);
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
	border-top: 1px solid rgb(219, 219, 219);
	border-bottom: 1px solid rgb(219, 219, 219);
	gap: 1rem;
}

.addCommentSection > img {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
}

.addCommentSection > div {
	background: var(--white-background);
	border-radius: 25px;
	width: 100%;
	border: 1px solid rgb(219, 219, 219);
	padding: 0.75rem 1rem;
	display: flex;
	align-items: center;
}

.commentInput {
	outline: none;
	flex-grow: 1;
	border: none;
	background: none;
	overflow: scroll;
	max-height: 90px;
	resize: none;
	font-weight: 300;
	font-size: 0.9rem;
}

.commentDisplaySection {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
}

.postDataCommnetSection {
	display: flex;
	padding: 1rem;
	gap: 1rem;
	border-bottom: 1px solid rgb(219, 219, 219);
}

.postDataCommnetSection > img {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
}

.postDataCommnetSection > div {
	padding-top: 1px;
}

.postDataCommnetSection > div > span:nth-child(1) {
	font-size: 0.9rem;
	font-weight: 500;
}

.postDataCommnetSection > div > span:nth-child(2) {
	font-size: 0.85rem;
	font-weight: 300;
	margin-left: 0.5rem;
	white-space: pre-wrap;
}

.commentPostButton {
	display: flex;
	align-items: center;
	max-height: 90px;
}

.commentPostButton > span {
	font-weight: 600;
	font-size: 14px;
	color: var(--primary2);
	padding-left: 3px;
	margin-left: 3px;
	border-left: 1px solid grey;
	min-height: 18px;
	height: 70%;
	display: flex;
	align-items: center;
}

.faintCommentPostButton {
	opacity: 0.5;
}

.commentPageComments {
	display: flex;
	flex-direction: column;
}

.commentPageComments > div {
	display: flex;
	padding: 0.5rem 1rem;
	gap: 1rem;
}

.commentPageComments > div > img {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
}

.commentPageComments > div > div > span:nth-child(1) {
	font-size: 0.9rem;
	font-weight: 500;
	margin-right: 0.5rem;
}

.commentPageComments > div > div > span:nth-child(2) {
	font-size: 0.85rem;
	font-weight: 300;
}
